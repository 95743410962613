import React from "react";
import { Link } from "gatsby";
import { useInView } from "react-intersection-observer";

// styles
import * as styles from "./Publications.module.css";

const Publications = props => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <div className={styles.publications_section}>
      <div
        ref={ref}
        className={`container scroll_animate ${inView && "inview"}`}
      >
        <div className="row">
          <div className="col-12 text-center">
            <h2 className={styles.publications_heading}>Publications</h2>
            <p className={styles.publications_subheading}>
              The revolutionary health outcomes of the trials are <br />
              published in 41 high-impact scientific journals.
            </p>
          </div>
        </div>

        <div className="row mt-4">
          <div className={`col-md-4 text-center`}>
            <div className={`${styles.publications_card}`}>
              <div className={`${styles.publications_card_heading}`}>
                Endocrine Practice - the Journal for Clinical Endocrinology
              </div>
              <div className={`${styles.publications_card_subheading}`}>
                Whole Body Digital Twin Technology for Remission of T2DM and
                Improvement in Fatty Liver
              </div>
              <a
                href="https://www.endocrinepractice.org/article/S1530-891X(23)00129-5/fulltext"
                className={`btn ${styles.publications_button}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                View
              </a>
            </div>
          </div>
          <div className={`col-md-4 text-center`}>
            <div className={`${styles.publications_card}`}>
              <div className={`${styles.publications_card_heading}`}>
                American Diabetes Association's <br />
                82nd Scientific Sessions
              </div>
              <div className={`${styles.publications_card_subheading}`}>
                Metabolic Benefits Beyond Glycemic Control with Artificial
                Intelligence, Internet of Things...
                <br />
                <br />
              </div>
              <a
                href="https://diabetesjournals.org/diabetes/article/71/Supplement_1/26-OR/146723/26-OR-Metabolic-Benefits-beyond-Glycemic-Control"
                className={`btn ${styles.publications_button}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                View
              </a>
            </div>
          </div>
          <div className={`col-md-4 text-center`}>
            <div className={`${styles.publications_card}`}>
              <div className={`${styles.publications_card_heading}`}>
                International Society for <br />
                Pharmacoeconomics, ISPOR 2021
              </div>
              <div className={`${styles.publications_card_subheading}`}>
                Health Economic Analysis of Digital Twin Technology for
                Remission of Diabetes:...
                <br />
                <br />
              </div>
              <a
                href="https://www.endocrinepractice.org/article/S1530-891X(21)01203-9/fulltext"
                className={`btn ${styles.publications_button}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                View
              </a>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <Link
              to="/reviewed-journals"
              className={`btn ${styles.publications_button_all}`}
            >
              View all
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publications;
