import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

import * as styles from "./DigitalTwin.module.css";

const DigitalTwin = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <div className="bgWhiteColor">
      <div
        ref={ref}
        className={`container scroll_animate ${inView && "inview"} ${
          styles.digitalTwinSec
        }`}
      >
        <div className="row">
          <div className={`col-6 ${styles.digitalTwinSec__bannerImgColMob}`}>
            <div className={styles.digitalTwinSec__bannerImg}>
              <StaticImage
                src="../../../images/home/mob_banner.png"
                alt="video"
                placeholder="blurred"
                loading="lazy"
              />
            </div>
          </div>
          <div className={`col-6 ${styles.digitalTwinSec__cotntCol}`}>
            <div
              className={`d-flex align-items-center ${styles.digitalTwinSec__cntnt1}`}
            >
              <div className={styles.digitalTwinSec__iconImg}>
                <StaticImage
                  src="../../../images/home/heart_icn.png"
                  alt="video"
                  placeholder="blurred"
                  loading="lazy"
                />
              </div>
              <p>Heal your metabolism to help normalize your blood sugar</p>
            </div>
            <div
              className={`d-flex align-items-center ${styles.digitalTwinSec__cntnt2}`}
            >
              <div className={styles.digitalTwinSec__iconImg}>
                <StaticImage
                  src="../../../images/home/med_icn.png"
                  alt="video"
                  placeholder="blurred"
                  loading="lazy"
                />
              </div>
              <p>Reduce costly medications including insulin</p>
            </div>
            <div
              className={`d-flex align-items-center ${styles.digitalTwinSec__cntnt3}`}
            >
              <div className={styles.digitalTwinSec__iconImg}>
                <StaticImage
                  src="../../../images/home/walk_icn.png"
                  alt="video"
                  placeholder="blurred"
                  loading="lazy"
                />
              </div>
              <p>Increase energy, improve mood, and decrease symptoms</p>
            </div>
          </div>
          <div className={`col-6 ${styles.digitalTwinSec__bannerImgCol}`}>
            <div className={styles.digitalTwinSec__bannerImg}>
              <StaticImage
                src="../../../images/home/mob_banner.png"
                alt="video"
                placeholder="blurred"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalTwin;
