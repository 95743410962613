import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Carousel from "react-multi-carousel";
import { useInView } from "react-intersection-observer";

// styles
import * as styles from "./RenownedResearch.module.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const RenownedResearch = props => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3
  });
  return (
    <>
      <div className={styles.renownedResearch_section}>
        <div
          ref={ref}
          className={`container scroll_animate ${inView && "inview"}`}
        >
          <div className="row">
            <div className="col-12 text-center">
              <h2 className={styles.renownedResearch_heading}>
                Backed by world-renowned research
              </h2>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Carousel
                responsive={responsive}
                infinite={true}
                swipeable={false}
                draggable={false}
                containerClass={styles.renownedResearch_multicarousel}
              >
                {/* <div className={`text-center`}>
                  <div>
                    <StaticImage
                      src="../../../images/renownedResearch/aace.png"
                      alt=""
                      className={`img-fluid ${styles.renownedResearch_image}`}
                      placeholder="blurred"
                      quality={90}
                    />
                  </div>
                  <p className={styles.renownedResearch_text}>
                    Digital Twin Enabled Precision Nutrition Found to Be Key for
                    Reversing Diabetes
                  </p>
                  <a
                    href="https://www.endocrinepractice.org/article/S1530-891X(21)01203-9/fulltext"
                    className={`btn ${styles.renownedResearch_button}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Learn more
                  </a>
                </div> */}
                <div className={`text-center`}>
                  <div>
                    <StaticImage
                      src="../../../images/renownedResearch/endocrine.png"
                      alt=""
                      className={`img-fluid ${styles.renownedResearch_image}`}
                      placeholder="blurred"
                      quality={90}
                    />
                  </div>
                  <p className={styles.renownedResearch_text}>
                    Remarkable Improvement in Parameters Determining Sustained
                    Diabetes Remission
                  </p>
                  <a
                    href="https://www.endocrine-abstracts.org/ea/0070/ea0070aep1027"
                    className={`btn ${styles.renownedResearch_button}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Learn more
                  </a>
                </div>
                <div className={`text-center`}>
                  <div>
                    <StaticImage
                      src="../../../images/renownedResearch/eas.png"
                      alt=""
                      className={`img-fluid ${styles.renownedResearch_image}`}
                      placeholder="blurred"
                      quality={90}
                    />
                  </div>
                  <p className={styles.renownedResearch_text}>
                    Initial Insights Found Digital Twin Technology to Support
                    Type 2 Diabetes Reversal
                  </p>
                  <a
                    href="https://easo.org/"
                    className={`btn ${styles.renownedResearch_button}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Learn more
                  </a>
                </div>
                <div className={`text-center`}>
                  <div>
                    <StaticImage
                      src="../../../images/renownedResearch/nature.png"
                      alt=""
                      className={`img-fluid ${styles.renownedResearch_image}`}
                      placeholder="blurred"
                      quality={90}
                    />
                  </div>
                  <p className={styles.renownedResearch_text}>
                    Retrospective study of glycemic variability, BMI, and blood
                    pressure in diabetes patients in the Digital Twin Precision
                    Treatment Program
                  </p>
                  <a
                    href="https://www.nature.com/articles/s41598-021-94339-6"
                    className={`btn ${styles.renownedResearch_button}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Learn more
                  </a>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RenownedResearch;
