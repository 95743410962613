// extracted by mini-css-extract-plugin
export var btnCon = "Banner-module--btnCon--CTxEl";
export var firstSectionHeader = "Banner-module--firstSectionHeader--baKl9";
export var firstSectionLearnMoreTxt = "Banner-module--firstSectionLearnMoreTxt--lIZUx";
export var firstSectionTitle = "Banner-module--firstSectionTitle--b+RAI";
export var fontColor = "Banner-module--fontColor--OE0NG";
export var iconImg = "Banner-module--iconImg--j4DkO";
export var newContentVideoModal = "Banner-module--newContentVideoModal--UUP2a";
export var newVideoModalClose = "Banner-module--newVideoModalClose--mwEYk";
export var playContainer = "Banner-module--playContainer--J-NFt";
export var topMar = "Banner-module--topMar--JJ7jm";
export var videoPlay = "Banner-module--videoPlay--mbOd+";