import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

import * as styles from "./PreciseHealing.module.css";

const PreciseHealing = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <div className="bgDarkBlue">
      <div
        ref={ref}
        className={`container scroll_animate ${inView && "inview"} ${
          styles.preciseHealingSec
        }`}
      >
        <div className={styles.preciseHealingSec__hdng}>
          <h3>Precisely healing the root cause</h3>
        </div>
        <div className={`row ${styles.preciseHealingSec__row}`}>
          <div
            className={`col-xs-12 col-sm-12 col-md-6 col-lg-6 ${styles.preciseHealingSec__bannerImgColMob}`}
          >
            <div>
              <StaticImage
                src="../../../images/home/precise_img.png"
                alt="video"
                placeholder="blurred"
                loading="lazy"
              />
            </div>
          </div>
          <div
            className={`col-xs-12 col-sm-12 col-md-6 col-lg-6 ${styles.preciseHealingSec__cntntCol}`}
          >
            <div className={styles.preciseHealingSec__cntnt}>
              <h3>
                Dysfunctional metabolism is the root cause of chronic metabolic
                disease.
              </h3>
              <p>
                A dysfunctional metabolism occurs when external factors –
                including nutrition, sleep, activity, and stress — disrupt vital
                life-sustaining chemical processes. This leads to negative
                downstream effects in every system in the body and results in
                organ damage and multiple chronic metabolic diseases.
              </p>
            </div>
            <div className={`mt-5 ${styles.preciseHealingSec__cntnt}`}>
              <h3>
                Healing the root cause reverses chronic metabolic disease.
              </h3>
              <p>
                The Whole Body Digital Twin™ delivers guidance targeted to
                improve the external factors causing metabolic damage —
                nutrition, sleep, activity, and stress. As these improve, the
                body’s chemical processes return to baseline function, which
                reduces negative downstream effects, which reverses organ damage
                and metabolic disease.
              </p>
            </div>
            <div className={`mt-5 ${styles.preciseHealingSec__cntnt}`}>
              <h3>
                Everyone’s metabolism is unique. Their guidance should be too.
              </h3>
              <p>
                Medical research has shown that each person’s metabolism
                functions differently, which creates significant challenges to
                healthcare providers’ ability to understand and customize
                treatment for each patient. The Whole Body Digital Twin™
                delivers guidance precisely tailored to each member’s unique
                metabolism.
              </p>
            </div>
          </div>
          <div
            className={`col-xs-12 col-sm-12 col-md-6 col-lg-6 ${styles.preciseHealingSec__bannerImgCol}`}
          >
            <div>
              <StaticImage
                src="../../../images/home/precise_img.png"
                alt="video"
                placeholder="blurred"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreciseHealing;
