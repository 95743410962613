// extracted by mini-css-extract-plugin
export var bannerv2__bottomPara = "BannerV2-module--bannerv2__bottomPara--TBGMt";
export var bannerv2__cardRow = "BannerV2-module--bannerv2__cardRow--v7SyD";
export var bannerv2__cardRow__card1 = "BannerV2-module--bannerv2__cardRow__card1--cPcEG";
export var bannerv2__cardRow__card1Icn = "BannerV2-module--bannerv2__cardRow__card1Icn--+ICYl";
export var bannerv2__cardRow__card2 = "BannerV2-module--bannerv2__cardRow__card2--rJkjC";
export var bannerv2__cardRow__card3 = "BannerV2-module--bannerv2__cardRow__card3--Z4ZRa";
export var bannerv2__cardRow__card4 = "BannerV2-module--bannerv2__cardRow__card4--98pJh";
export var bannerv2__cardRow__cards = "BannerV2-module--bannerv2__cardRow__cards--uXiyU";
export var bannerv2__hdng = "BannerV2-module--bannerv2__hdng--bTXAi";
export var bannerv2__line = "BannerV2-module--bannerv2__line--teB0l";
export var bannerv2__para = "BannerV2-module--bannerv2__para--IYzYh";
export var bannerv2__playIcon = "BannerV2-module--bannerv2__playIcon--Y0J5r";
export var bannerv2__subHdng = "BannerV2-module--bannerv2__subHdng--sPkrP";
export var bannerv2__videoImg = "BannerV2-module--bannerv2__videoImg--dfOGJ";