import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

// styles
import * as styles from "./Featured.module.css";

const Featured = props => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  return (
    <div className={styles.featured_section}>
      <div
        ref={ref}
        className={`container scroll_animate ${inView && "inview"} ${
          styles.featured_card
        }`}
      >
        <div className="row">
          <div className="col-12 text-center">
            <h2 className={styles.featured_heading}>Featured in</h2>
          </div>
        </div>

        <div className="row">
          <div className={`col-6 col-md-3 text-center`}>
            <a
              href="https://www.wsj.com/articles/twin-health-gathers-140-million-to-tackle-metabolic-diseases-11633528800"
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className={`${styles.featured_card_hover}`} role="button">
                <StaticImage
                  src="../../../images/featured/wsj.png"
                  alt="wsj"
                  className={`img-fluid ${styles.featured_image}`}
                  placeholder="blurred"
                  quality={100}
                />
                <div className="info">
                  <div className={`${styles.featured_card_hover_link}`}>
                    Learn more →
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className={`col-6 col-md-3 text-center`}>
            <a
              href="https://www.cbinsights.com/research/report/digital-health-startups-redefining-healthcare-2022/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className={`${styles.featured_card_hover}`}>
                <StaticImage
                  src="../../../images/featured/deigital-health.png"
                  alt="deigital-health"
                  className={`img-fluid ${styles.featured_image}`}
                  placeholder="blurred"
                  quality={100}
                />
                <div className="info">
                  <div className={`${styles.featured_card_hover_link}`}>
                    Learn more →
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className={`col-6 col-md-3 text-center`}>
            <a
              href="https://www.linkedin.com/posts/ehir_its-that-time-of-year-again-our-year-in-activity-6886744747369668608-j2-L/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className={`${styles.featured_card_hover}`}>
                <StaticImage
                  src="../../../images/featured/ehr.png"
                  alt="ehr"
                  className={`img-fluid ${styles.featured_image}`}
                  placeholder="blurred"
                  quality={100}
                />
                <div className="info">
                  <div className={`${styles.featured_card_hover_link}`}>
                    Learn more →
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className={`col-6 col-md-3 text-center`}>
            <a
              href=" https://www.zdnet.com/article/neural-network-approach-to-diabetes-treatment-nets-140-million-series-c-for-startup-twin-health/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className={`${styles.featured_card_hover}`} role="button">
                <StaticImage
                  src="../../../images/featured/zd-net.png"
                  alt="zd-net"
                  className={`img-fluid ${styles.featured_image}`}
                  placeholder="blurred"
                  quality={100}
                />
                <div className="info">
                  <div className={`${styles.featured_card_hover_link}`}>
                    Learn more →
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
