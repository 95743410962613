import React from "react";
import { Link } from "gatsby";
import { useInView } from "react-intersection-observer";

// components
import Carousel from "./Carousel";

//styles
import * as styles from "./SucessStories.module.css";

const SuccessStoriesV1 = props => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  return (
    <div className={"bgWhiteColor"}>
      <div
        ref={ref}
        className={`container scroll_animate ${inView && "inview"} ${
          styles.sucessStoriesV1_container
        } bgWhiteColor`}
      >
        <div className="row">
          <div className="col-12 text-center">
            <h2 className={styles.sucessStoriesV1_heading}>
              Real results from real members
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <Carousel carouselId="successStoriesV1" showIndicators={false} />
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <Link
              to="/testimonials"
              className={`btn ${styles.sucessStoriesV1_button_all}`}
            >
              View all
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessStoriesV1;
