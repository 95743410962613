import React, { useEffect } from "react";

// seo
import Seo from "../components/Seo";

// services
import { sendToCT } from "../services/Clevertap";

// constants
import constant from "../constants";

// components
import BannerV2 from "../components/Home/BannerV2/BannerV2";
// import CardsCta from "../components/Home/CardsCtaV2/CardsCta";
import DigitalTwin from "../components/Home/DigitalTwin/DigitalTwin";
import FloatingCta from "../components/Home/FloatingCta/FloatingCta";

import PreciseHealing from "../components/Home/PreciseHealing/PreciseHealing";
import ReduceMedication from "../components/Home/ReduceMedication/ReduceMedication";

import TwinCanHelp from "../components/Home/TwinCanHelp/TwinCanHelp";
import RenownedResearch from "../components/Home/RenownedResearch/RenownedResearch";
import Publications from "../components/Home/Publications/Publications";
import SuccessStoriesV1 from "../components/Home/SuccessStories/SuccessStoriesV1";
import Featured from "../components/Home/Featured/Featured";
import GetInTouch from "../components/Home/GetInTouch/GetInTouch";

const IndexPage = props => {
  useEffect(() => {
    return sendToCT("Website Launched", {
      action: "Website_Launched",
    });
  }, []);

  return (
    <>
      <Seo
        title="Reverse Diabetes"
        description="Twin reverse's type 2 diabetes using AI based precision treatment. Learn how 97% reduced blood sugar, medicines & 98% got rid of all symptoms."
      />

      <FloatingCta
        floatingLeftCtaText={constant.HOME_PAGE_V2.floatingLeftCtaText}
        floatingLeftCtaLink={constant.HOME_PAGE_V2.floatingLeftCtaLink}
        floatingRightCtaText={constant.HOME_PAGE_V2.floatingRightCtaText}
        floatingRightCtaLink={constant.HOME_PAGE_V2.floatingRightCtaLink}
      />

      <div id="about-twin"></div>
      <BannerV2 videoLink={constant.HOME_PAGE_V2.topBannerVideoLink} />

      {/* <CardsCta /> */}

      <ReduceMedication adaLink={constant.HOME_PAGE_V2.adaLink} />

      <DigitalTwin />

      <PreciseHealing />

      <TwinCanHelp videoLink={constant.HOME_PAGE_V2.twinCanHelpVideoLink} />

      <RenownedResearch />

      <Publications />

      <div id="success-stories"></div>
      <SuccessStoriesV1 />

      <Featured />

      <div id="getInTouch">
        <GetInTouch />
      </div>
    </>
  );
};

export default IndexPage;
