import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

// modals
import VideoModal from "./VideoModal";

// styles
import * as styles from "./TwinCanHelp.module.css";

const TwinCanHelp = props => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3
  });

  const [showVideoModal, setShowVideoModal] = useState(false);

  const toggleVideo = () => {
    setShowVideoModal(!showVideoModal);
  };

  return (
    <>
      <div className={styles.twinCanHelp_section}>
        <div
          ref={ref}
          className={`container scroll_animate ${inView && "inview"}`}
        >
          <div className="row">
            <div className="col-12 text-center">
              <h2 className={styles.twinCanHelp_heading}>How Twin can help</h2>
            </div>
          </div>

          <div className="row">
            <div
              className={`col-12 text-center`}
              style={{ position: "relative" }}
            >
              <StaticImage
                src="../../../images/twinCanHelp/jahangir.png"
                alt="jahangir"
                className={`img-fluid ${styles.twinCanHelp_image}`}
                placeholder="blurred"
                quality={90}
              />
              <StaticImage
                src="../../../images/twinCanHelp/play.png"
                alt="play"
                className={`img-fluid ${styles.twinCanHelp_image_play}`}
                placeholder="blurred"
                quality={100}
                role="presentation"
                onClick={() => {
                  toggleVideo();
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <VideoModal
        show={showVideoModal}
        onHide={toggleVideo}
        videoLink={props.videoLink}
      />
    </>
  );
};

export default TwinCanHelp;
