import React from "react";
// import { Link } from "gatsby";
import styled from "styled-components";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// ct
import { sendToCT } from "../../../services/Clevertap";

// sticky header
import StickyHeader from "../../Header/StickyHeader";

const MainWrapper = styled.div`
  position: fixed;
  bottom: 5px;
  left: 0px;
  width: 100%;
  z-index: 100;
`;

// const GradientBtn = styled.div`
//   background: linear-gradient(281.07deg, #ff9400 11.7%, #ff008c 91.82%);
//   border-radius: 50px;
//   padding: 7px 20px;
//   display: flex !important;
//   flex: 1;
//   align-items: center;
//   justify-content: space-evenly;
//   line-height: 42px;
//   min-width: 140px;
//   width: 100%;
//   text-align: center;

//   > p {
//     font-weight: 700;
//     font-size: 16px;
//     mix-blend-mode: normal;
//     color: #ffffff;
//   }
// `;

const TransparentBtn = styled.div`
  border-radius: 50px;
  padding: 7px 20px;
  line-height: 42px;
  background: #ffffff;
  border: 1px solid #F37920;
  box-sizing: border-box;
  min-width: 140px;
  width: 100%;
  text-align: center;

  > p {
    font-weight: 700;
    font-size: 16px;
    color: #151336;
  }
`;

const FloatingCta = props => {
  const { isSticky } = StickyHeader();

  // console.log("isSticky", isSticky);

  return (
    <>
      {isSticky && (
        <MainWrapper>
          <div className="d-block d-lg-none d-xl-none d-xxl-none">
            <div className={`container twinContainer`}>
              <div className="row">
                <div className="col">
                  <div className="d-flex justify-content-between">
                    {props.floatingLeftCtaText && (
                      <AnchorLink
                        to={props.floatingLeftCtaLink || "#"}
                        className={`navLink w-100 ${
                          props.floatingRightCtaText && "me-2"
                        }`}
                        onClick={() =>
                          sendToCT("Floating_CTA1", {
                            action: "user clicks on attend webinar",
                          })
                        }
                      >
                        <TransparentBtn>
                          <p>{props.floatingLeftCtaText}</p>
                        </TransparentBtn>
                      </AnchorLink>
                    )}
                    {/* {props.floatingRightCtaText && (
                      <Link
                        to={props.floatingRightCtaLink || "#"}
                        className={`navLink w-100`}
                        onClick={() =>
                          sendToCT("Floating_CTA2", {
                            action: "user clicks on get started",
                          })
                        }
                      >
                        <GradientBtn>
                          <p>{props.floatingRightCtaText}</p>
                        </GradientBtn>
                      </Link>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MainWrapper>
      )}
    </>
  );
};

export default FloatingCta;
