import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { useInView } from "react-intersection-observer";

import * as styles from "./ReduceMedication.module.css";

const ReduceMedication = props => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <div className="bgWhiteColor">
      <div
        ref={ref}
        className={`container scroll_animate ${inView && "inview"} ${
          styles.reduceMedContainer
        }`}
      >
        <div className="row">
          <div className={styles.reduceMedContainer__hdng}>
            <h3>
              Reduce medication, lose weight, and recover from metabolic
              diseases
            </h3>
          </div>
          <div
            className={`col-xs-12 col-sm-12 col-md-6 col-lg-6 ${styles.reduceMedContainer__imgCol}`}
          >
            <div className={styles.reduceMedContainer__img}>
              <StaticImage
                src="../../../images/home/new_logo_banner_img.png"
                alt="video"
                placeholder="blurred"
                loading="lazy"
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div className={styles.reduceMedContainer__content}>
              <h2>
                Reduce medication, lose weight, and recover from metabolic
                diseases
              </h2>
              <h3>
                “In total, nearly 84% of the group who followed their digital
                twin’s guidance for six months were determined to be in
                remission by the end of that period, per the ADA’s
                standards—meaning they maintained normal blood glucose levels
                for at least three months without taking diabetes medication."
              </h3>
              <h5>– American Diabetes Association (ADA)*</h5>
              <p>
                The Whole Body Digital Twin™ is a digital representation of your
                unique metabolism and delivers precise, personalized guidance
                about foods, sleep, activity, and breathing through the
                easy-to-use app.
              </p>
              <p className="mt-3">
                Twin Health’s program combines the Whole Body Digital Twin™ with
                a dedicated care team that monitors your sensor data, offers
                personalized recommendations, and supports you on your health
                journey.
              </p>
              <div className={styles.reduceMedContainer__adaContent}>
                <div className={styles.reduceMedContainer__adaContent__img}>
                  <StaticImage
                    src="../../../images/home/ada_logo2.png"
                    alt="video"
                    placeholder="blurred"
                    loading="lazy"
                  />
                </div>
                <div className={styles.reduceMedContainer__adaContent__cntnt}>
                  <Link to={props.adaLink} target="_blank">
                    <p>
                      *Artificial Intelligence Offers Significant Rate of
                      Remission for Type 2 Diabetes Compared to Standard Care.
                      American Diabetes Association (2022, June 4).
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReduceMedication;
